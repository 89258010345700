import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import ProjectsInActive from './icons/ProjectsInactive.png';
import AeroGCSInactive from './sidebaricon/AeroGCSInactive.png';
import DronesActive from './dronesActive.png';
import RPAS from './rpas.png';
import { addUserAnalytic, updateOutTime, checkSubscriptionAndStorage } from './ReusableComponents/reusableFunctions.js';
import { getActiveTeam } from './Teams/index.js';
import SubscriptionExpireModal from './subscription/Reusable/SubscriptionExpireModal.js';
import AppConstants from './AppConstants.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import CsvDownload from 'react-json-to-csv';
import TableView from './ReusableComponents/TableView/TableView.js';
import { withRouter } from 'react-router-dom';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs.js';
import SearchHeader from './ReusableComponents/SearchHeader/SearchHeader.js';
import { debounnceFn } from '././ReusableComponents/reusableFunctions.js'
import RegisterDronePopup from './RegisterDronePopup.js';
import Cookies from "universal-cookie";
import TableViewPagination from './ReusableComponents/TableView/TableViewPagination.js';
import { ManageDroneAccess } from './Teams/ManageDroneAccess.js';
const cookies = new Cookies();
class DrnMgmtDrones extends Component {
    constructor(props) {
        super(props);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.getDronesData = this.getDronesData.bind(this);
    }
    state = {
        user: {},
        type: "",
        organizations: [],
        drones: [],
        unregisteredAssignedDrones: [],
        sortType: 'Last Modified',
        debounceSearchFn: undefined,
        droneLoading: false,
        sortTypeName: false,
        sortTypeDate: false,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        showSubscriptionExpirePopup: false,
        loading: true,
        droneCount: "",
        filter: {
            searchString: '',
            fromDateTime: undefined,
            toDateTime: undefined,
            orderBy: 'registered_date',
            orderType: 'DESC',
            pageSize: 15,
            pageNumber: 1,
        },
        outId: "",
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    handleFilterChange = (newFilter, updatedField) => {
        const { fromDateTime, toDateTime } = newFilter;
        const isoFromDateTime = fromDateTime === undefined ? undefined : new Date(fromDateTime).toISOString();
        const isoToDateTime = toDateTime === undefined ? undefined : new Date(toDateTime).toISOString();
        this.setState({
            filter: {
                ...this.state.filter,
                ...newFilter,
                fromDateTime: fromDateTime === undefined || toDateTime === undefined ? undefined : isoFromDateTime,
                toDateTime: fromDateTime === undefined || toDateTime === undefined ? undefined : isoToDateTime,
            }
        }, () => {
            if (updatedField === "searchString") {
                this.debounnceSearchFn();
            } else {
                this.getDronesData();
            }
        });
    }

    handleDownloadDronesCSV = async () => {
        try {
            await API.downloadDronesCSV(this.state.filter)
        } catch (err) {
            console.log(err)
        }
    }


    getDronesData = () => {
        this.setState((state) => ({
            ...state,
            droneLoading: true,
        }))

        cookies.set('drone_list',
            this.state.filter,
            { path: "/drone_management/drones" })

        API.getGCSDrones(this.state.filter).then((result) => {
            console.log("result : ", result);
            this.setState(state => ({
                ...state,
                droneLoading: false,
                drones: result.drones,
                droneCount: result.droneCount,
                unregisteredAssignedDrones: result.unregisteredAssignedDrones,
                loading: false
            }))
        }).catch(err => {
            this.setState((state) => ({
                ...state,
                droneLoading: false,
            }))
            console.log(err);
        })
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        this.debounnceSearchFn = debounnceFn(this.getDronesData, 700);
        document.title = "Drones - Device Management";
        let cookiesData = cookies.get("drone_list");
        if (!cookiesData) {
            cookies.set('drone_list',
                JSON.stringify(this.state.filter),
                { path: "/drone_management/drones" })
        }
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        getActiveTeam().then(activeTeam => {
            this.setState({
                activeTeam,
                filter: cookiesData ? cookiesData : this.state.filter
            }, () => {
                checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
                    if (data.error || data.storageExceed || data.subscriptionExpire || !data.isValidStoragePlan || data.isExpired)
                        this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
                    else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
                })

                const isoDefaultFromDateTime = this.state.filter.fromDateTime !== undefined ? new Date(this.state.filter.fromDateTime).toISOString() : undefined;
                const isoDefaultToDateTime = this.state.filter.fromDateTime !== undefined ? new Date(this.state.filter.toDateTime).toISOString() : undefined;

                // Update filter state with default values
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        fromDateTime: isoDefaultFromDateTime,
                        toDateTime: isoDefaultToDateTime
                    }
                }), () => {
                    // Call getDronesData after updating filter
                    // this.getDronesData();
                });


                if (this.props.location.state) {
                    let { user, type } = this.props.location.state;
                    this.setState((state) => ({
                        ...state,
                        user,
                        type,
                        filter: cookiesData ? cookiesData : this.state.filter
                    }), () => { this.getDronesData(); })
                } else {
                    API.getUserType().then(([blah, type, user]) => {
                        this.setState((state) => ({
                            ...state,
                            user,
                            type,
                            filter: cookiesData ? cookiesData : this.state.filter
                        }), () => { this.getDronesData(); })
                    }, (e) => {
                        window.location.replace("/login");
                    })
                }
            })
        })
    }



    render() {
        let { user, type, customerDisplay, organizations, sidebarMode } = this.state
        let sidebarConfigurations = null
        sidebarConfigurations = {
            options:
                [
                    // {
                    //     icon: service,
                    //     text: "Services",
                    //     path: "/"
                    // },
                    {
                        icon: AeroGCSInactive,
                        text: "AeroGCS",
                        path: "/"
                    },
                    {
                        icon: DronesActive,
                        text: "Drones",
                        path: "/drone_management/drones",
                        selected: true,
                    },
                    {
                        icon: RPAS,
                        text: "Drone Control Center",
                        path: "/drone_control_center/all_drones",
                    },
                    {
                        icon: ProjectsInActive,
                        text: "Projects",
                        path: "/projects",
                    },
                ],
            user,
            type,
            mode: this.state.sidebarMode,
            aeroGCSHelp: true,
        }


        return (user.username ? <div className="wrapper">
            {this.state.showRegisterDronePopup && <RegisterDronePopup onRequestClose={(success) => {
                this.setState({ showRegisterDronePopup: false }, () => {
                    if (success) {
                        // this.getDronesData();
                    }
                })
            }} />}
            <ManageDroneAccess
                drone={this.state.selectedManageDroneAccess}
                closeManageAccess={() => {
                    this.setState({
                        selectedManageDroneAccess: false,
                        //     selectedProjectData: undefined
                    })
                }}
                senderData={user}
            // propsData={this.state.selectedProjectData}
            />
            <div className="right-content-new"
                onClick={
                    () => {
                        this.setState((state) => ({
                            ...state, sidebarMode: 'shrunk'
                        }))
                    }
                }
            >
                <div className="top-bar-new">
                    <div className="top-bar-text-new">
                        <BreadCrumbs
                            data={[
                                {
                                    displayName: "AeroGCS",
                                    pathname: "/",
                                    state: {
                                        user
                                    }
                                },
                                {
                                    displayName: 'Drones',
                                }
                            ]} />
                    </div>
                </div>
                {this.state.loading ? <LoaderComponent height="90%" width="100%" />
                    : <div className="main-content-new">
                        {this.state.showSubscriptionExpirePopup ?
                            <SubscriptionExpireModal
                                data={this.state.showSubscriptionExpirePopup}
                            /> : <></>}

                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', marginBottom: "10px" }}>
                            <div style={{ display: "flex", alignContent: "center" }}>
                                {/* <div className="services-content-title" style={{ fontSize: "20px" }}>
                                        {`Drones (${this.state.droneCount})`}
                                    </div> */}
                            </div>
                            <div style={{
                                marginLeft: "auto", width: "fit-content",
                                // position: "absolute",
                                top: "115px",
                                right: "70px",
                            }}>
                                {this.state.drones.length > 0 && this.state.activeTeam.isOwner &&
                                    <button className="new-button new-btn-active new-btn-lg download-data-btn" onClick={this.handleDownloadDronesCSV}>Download Data</button>
                                }
                            </div>
                        </div>
                        {this.state.loading ? <LoaderComponent height={'90vh'} />
                            : <>
                                <div>
                                    <SearchHeader
                                        defaultFromDate={this.state.filter.fromDateTime}
                                        defaultToDate={this.state.filter.toDateTime}
                                        isShowDateTimePicker={true}
                                        searchString={this.state.filter.searchString}
                                        placeholder={"Search drones.."}
                                        onFilterDataChange={(newFilter, updatedField) => {
                                            cookies.set('drone_list',
                                                JSON.stringify({
                                                    ...this.state.filter,
                                                    searchString: newFilter.searchString,
                                                    fromDateTime: newFilter.fromDateTime,
                                                    toDateTime: newFilter.toDateTime,
                                                }),
                                                { path: "/drone_management/drones" })
                                            this.handleFilterChange(newFilter, updatedField)
                                        }}
                                    />
                                </div>
                                <div style={{ position: 'relative' }} className={"drone_mag_table_height"}>
                                    <TableView
                                        hidePagination
                                        columns={[
                                            { Header: 'Drone ID', accessor: 'drone_id', width: '25%', },
                                            { Header: 'Drone UIN', accessor: 'uin', width: '20%', },
                                            { Header: 'Drone Name', accessor: 'drone_name', width: '20%', isSortable: true },
                                            { Header: 'Registered Date', accessor: 'registered_date', width: '15%', isSortable: true, isDate: true,   },
                                            { Header: 'Status', accessor: 'registeredStatus', width: '15%' }
                                        ]}
                                        onClickRow={(drone) => {
                                            this.props.history.push(`/drone_management/drone_details`, { user, drone })
                                        }}
                                        data={this.state.drones.map((d) => {
                                            return ({
                                                ...d,
                                                registeredStatus: d.is_registered ? 'Registered' : 'Unregistered',
                                                // opacity: d.is_registered ? "1" : "0.5"
                                            })
                                        }) || []}
                                        user={user}
                                        isLoading={this.state.droneLoading}
                                        headingLeft="Drones"
                                        count={this.state.droneCount}
                                        onStateChange={(changedState) => {
                                            this.setState({
                                                filter: {
                                                    ...this.state.filter,
                                                    pageNumber: changedState.pageNumber,
                                                    orderBy: changedState.orderBy,
                                                    orderType: changedState.orderType
                                                }
                                            }, () => { this.getDronesData() })
                                        }}
                                        activities={this.state.activeTeam.isOwner && {
                                            hideDelete: true,
                                            canManage: true,
                                            onClickManage: (drone) => {
                                                console.log(drone);
                                                this.setState({
                                                    selectedManageDroneAccess: drone,
                                                    // selectedProjectData: { pathname: "/plans", state: { user, type, project, sidebarMode: this.state.sidebarMode } }
                                                })
                                            },
                                        }}
                                    />

                                    {/* <div
                                            style={{ position: 'absolute', right: 0, top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            onClick={() => {
                                                this.setState({ showRegisterDronePopup: true })
                                            }}
                                            className="new-button new-btn-active new-btn-lg ">
                                            Register Drone
                                        </div> */}

                                </div>
                                <div className='grid-view-pagination-width'>
                                    <TableViewPagination
                                        currentPage={this.state.filter.pageNumber}
                                        totalCount={this.state.droneCount}
                                        pageSize={this.state.filter.pageSize}
                                        padding="10px 5px"
                                        onPageChange={(page) => {
                                            this.setState({
                                                filter: {
                                                    ...this.state.filter,
                                                    pageNumber: page
                                                }
                                            }, () => {
                                                cookies.set('drone_list',
                                                    JSON.stringify(this.state.filter),
                                                    { path: "/drone_management/drones" })

                                                if (page == this.state.filter.pageNumber) this.getDronesData()
                                            })
                                        }}
                                    />
                                </div>
                            </>}
                    </div>}
            </div>
        </div > : <div />
        );
    }
}

const mapStateToProps = state => {
    return {
        SubscriptionData: state.subscription.subscriptionData,
    };
};

const ConnectedDrnMgmtDrones = connect(mapStateToProps, null)(DrnMgmtDrones)
export default withRouter(ConnectedDrnMgmtDrones);
