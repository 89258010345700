import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import { withRouter } from 'react-router-dom';
import searchIcon from "./icons/SearchIcon.png";
import Loader from './icons/loader.svg';
import { addUserAnalytic, getFormatedDate, updateOutTime } from './ReusableComponents/reusableFunctions.js';
import DropDownWithButton from './ReusableComponents/DropDownWithButton';
import RMFlightDetails from './RMFlightDetails.js';
import TableView from './ReusableComponents/TableView/TableView.js';
class RMPAllFlightsLogs extends Component {

    state = {
        user: {},
        type: "",
        key: " ",
        fromDate: '-30610245208',
        toDate: Date.now(),
        loader: true,
        refreshing: false,
        userperpage: 20,
        currentpage: 1,
        totalCount: 0,
        taskdisplay: [],
        logsDisplay: [],
        projectName: '',
        planName: '',
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        currentPilot: {},
        changePilot: false,
        outId: ""
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }


    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        this.setState(state => ({
            ...state,
            loading: true,
            loader: true
        }))
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))

        document.title = "All Flight Logs - RMP";
        if (this.props.location.state) {
            let { user, type, startDate, endDate, pilots, totalAreaCovered, totalDistanceCovered } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
                totalAreaCovered,
                totalDistanceCovered,
                pilots,
            }), () => {
                this.getLogs()
            })
            if (startDate && endDate) {
                this.setState((state) => ({
                    ...state,
                    fromDate: startDate,
                    toDate: endDate
                }), () => {
                    this.getLogs()
                })
            }
            if (pilots) {
                this.setState((state) => ({
                    ...state,
                    currentPilot: pilots.length > 0 ? pilots[0] : { pilot_id: user.userid, first_name: user.first_name, last_name: user.last_name }
                }))
            }
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    getLogs = () => {
        this.setState(state => ({
            ...state,
            logsDisplay: [],
            loading: true,
            loader: true
        }))
        const { user, type, taskdisplay, currentpage, userperpage, key, pilots, currentPilot, fromDate, toDate } = this.state;
        const lastIndex = currentpage * userperpage;
        const firstpage = lastIndex - userperpage;
        if (pilots) {
            API.getAeroGCSFlightsWithPilots(key == "" ? " " : key, currentPilot.pilot_id, fromDate, toDate, firstpage, userperpage).then(async (data) => {
                let logs = await data.data;
                let count = await data.flightCount
                if (!data.data || data.data.length == 0) {
                    this.setState(state => ({
                        ...state,
                        logsDisplay: [],
                        totalCount: count,
                        loading: false,
                        loader: false
                    }))
                }
                else {
                    this.setState(state => ({
                        ...state,

                        logsDisplay: logs,
                        totalCount: count,
                        loading: false,
                        loader: false
                    }))
                }

            }, (e) => {
                console.log(e);
            })
        }
        else {
            API.getAllFlightsLogs(key == "" ? " " : key, fromDate, toDate, firstpage, userperpage, null).then(async (data) => {
                let logs = await data.logs;
                let count = await data.count
                if (data.logs.length == 0) {
                    this.setState(state => ({
                        ...state,
                        logsDisplay: data.logs,
                        totalCount: data.count,
                        loading: false,
                        loader: false
                    }))
                }
                else {
                    this.setState(state => ({
                        ...state,
                        logsDisplay: logs,
                        totalCount: count,
                        loading: false,
                        loader: false
                    }))
                }
            }, (e) => {
                console.log(e);
            })
        }
    }


    fromDate = (date) => {
        if (date && date.length > 0) {
            let d = new Date(date + " 00:00:00+00")
            let date1 = d.getTime() / 1000
            this.setState((state) => ({
                ...state,
                fromDate: date1,
                currentpage: 1
            }),
                () => this.getLogs());
        }
        else {
            this.setState((state) => ({
                ...state,
                fromDate: '-30610245208',
                currentpage: 1
            }),
                () => this.getLogs());
        }
    }
    toDate = (date) => {
        if (date && date.length > 0) {
            let d = new Date(date + " 23:59:00+00")
            let date1 = d.getTime() / 1000
            this.setState((state) => ({
                ...state,
                toDate: date1,
                currentpage: 1
            }),
                () => this.getLogs());
        } else {
            this.setState((state) => ({
                ...state,
                toDate: Date.now(),
                currentpage: 1
            }),
                () => this.getLogs());
        }
    }
    search(key) {
        this.setState((state) => ({
            ...state,
            key,
            firstPage: key,
            currentpage: 1
        }),
            () => this.getLogs());
    }
    firstpage = () => {
        if (this.state.currentpage > 1 || this.state.changePilot) {
            this.setState((state) => ({
                ...state,
                currentpage: 1,
                changePilot: false
            }), () => {
                this.getLogs()
            });
        }
    }

    prevpage = () => {
        if (this.state.currentpage > 1) {
            this.setState((state) => ({
                ...state,
                currentpage: this.state.currentpage - 1
            }), () => {
                this.getLogs()
            }
            );
        }
    }
    nextpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.totalCount / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: state.currentpage + 1
            }), () => {
                this.getLogs()
            });
        }
    }

    lastpage = () => {
        if (this.state.currentpage < Math.ceil(this.state.totalCount / this.state.userperpage)) {
            this.setState((state) => ({
                ...state,
                currentpage: Math.ceil(this.state.totalCount / this.state.userperpage)
            }), () => {
                this.getLogs()
            });
        }
    }

    changepage(num) {
        if (num >= 1 && num <= (Math.ceil(this.state.totalCount / this.state.userperpage))) {
            this.setState((state) => ({
                ...state,
                currentpage: num,
            }), () => this.getLogs());
        }

    }




    render() {
        const { currentpage, currentPilot, totalDistanceCovered, totalAreaCovered, pilots } = this.state;
        let { user, type, origin } = this.props.location.state;

        return this.state.openFlightDetails ?
            <RMFlightDetails states={
                {
                    user,
                    type,
                    sidebarMode: this.state.sidebarMode,
                    flightId: this.state.flightId,
                    planName: this.state.planName,
                    projectName: this.state.projectName,
                    date: this.state.date,
                    startTime: this.state.startTime,
                    end_time: this.state.endTime,
                    streamKey: this.state.streamKey
                }} goback={() => this.setState({ openFlightDetails: false })} />
            : (
                <div style={{ background: "#f5f6f8" }} className="wrapper">
                    {/* <SideBar config={sidebarConfigurations} parentSidebarSwitch={this.parentSidebarSwitch} /> */}
                    <div className="right-content-new" onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                    >
                        <div className="top-bar-new" style={{ display: "flex" }}>
                            <div className="top-bar-text-new">{origin === 'flightsCard' ? "FLIGHTS" : origin === 'acreCard' ? "AREA COVERED" : origin === 'kilometerCard' ? "DISTANCE COVERED" : origin === 'pilotCard' ? "PILOT FLIGHTS " : "FLIGHTS "}</div>
                        </div>
                        <div style={{ width: "100%", alignContents: "center", alignText: "center", height: 'calc(100% - 65px)' }}>
                            {/* <SnackBar user={user} sidebarMode={sidebarMode} type={type} /> */}
                            <div style={{ display: "flex", margin: "1% 2%", alignContent: "center" }}>
                                <div className="services-content-title" > {"Total Flights : "}</div>
                                <div className="data-count"> {this.state.totalCount} </div>

                                {totalAreaCovered || totalDistanceCovered || pilots ? <div style={{ height: "25px", margin: "0% 2%", borderLeft: "3px solid #C8C8C8" }}></div> : ''}

                                <div className="services-content-title" > {totalAreaCovered ? `Total Area Covered : ` :
                                    (totalDistanceCovered ? `Total Distance Covered : ` :
                                        (pilots ? `Total Pilots : ` : ''))}
                                </div>
                                <div className="services-content-title" style={{ marginLeft: "4px", color: "#2989cf" }}> {totalAreaCovered ? (Number(totalAreaCovered) / 4046.85642).toFixed(4) + " Acres" :
                                    (totalDistanceCovered ? (Number(totalDistanceCovered) / 1000) + " km" :
                                        (pilots ? pilots.length : ''))}
                                </div>
                            </div>

                            <div style={{ display: "flex", margin: "1% 2%", justifyContent: "space-between", alignItems: 'center' }}>
                                <div className="add-user-form-row-left">
                                    <div className="add-user-form-text-wrapper" style={{ display: 'flex', alignItems: 'center' }} >
                                        <input type="text" className="add-user-form-text" maxLength={30} placeholder="Search By Drone ID" icon={searchIcon} style={{ width: "333px", height: "5%" }} onChange={(event) => this.search(event.target.value)} />
                                        <img src={searchIcon} style={{ height: "14px", width: "14px", position: 'relative', left: "-30px" }} />

                                        {pilots && pilots.length > 0 && <div
                                            style={{ width: "35vh", height: "5%" }}
                                            className="add-user-form-text">
                                            <DropDownWithButton
                                                list={
                                                    pilots.map(element => ({
                                                        name: element.first_name + " " + element.last_name,
                                                        onClick: () => {
                                                            this.setState({
                                                                currentPilot: element,
                                                                changePilot: true
                                                            }, () => {
                                                                this.firstpage()
                                                            })

                                                        }
                                                    }))}
                                                selectedItem={currentPilot.first_name + " " + currentPilot.last_name}
                                                width={'99%'}
                                                textAlign={'left'}
                                                textFontSize={'13px'}
                                                textFontWeight={'300'}
                                                padding={'7px 5px'}
                                            />
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                    <div className="add-user-form-row-left" style={{ marginLeft: "50px" }}>
                                        <div className="add-user-form-text-wrapper" style={{ display: "flex" }} >
                                            <label style={{ color: "#666666", fontFamily: "Poppins", fontSize: "12px", paddingTop: "7px" }}>From :</label>
                                            <input
                                                className="add-user-form-text"
                                                type="date"
                                                max='9000-01-01'
                                                defaultValue={this.state.fromDate}
                                                onChange={(event) => this.fromDate(event.target.value)}
                                                style={{
                                                    borderRadius: '10px',
                                                    marginLeft: "8px",
                                                    height: "30px",
                                                    width: "125px",
                                                }} />
                                        </div>
                                    </div>
                                    <div className="add-user-form-row-left" style={{ marginLeft: "20px" }}>
                                        <div className="add-user-form-text-wrapper" style={{ display: "flex" }} >
                                            <label style={{ color: "#666666", fontFamily: "Poppins", fontSize: "12px", paddingTop: "7px" }}>To :</label>
                                            <input
                                                className="add-user-form-text"
                                                type="date"
                                                max='9000-01-01'
                                                onChange={(event) => this.toDate(event.target.value)}
                                                defaultValue={this.state.toDate}
                                                style={{
                                                    backgroundColor: "white",
                                                    marginLeft: "8px",
                                                    borderRadius: '10px',
                                                    outline: "none",
                                                    height: "30px",
                                                    width: "125px",
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "96%", margin: "1% 2%", borderTop: "1px solid #E0E0E0" }}></div>
                            {
                                this.state.loader ? <div style={{ height: "416px", width: "100%", display: "flex", textAlign: "center" }}>
                                    <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                                </div> :
                                    <div style={{ padding: "0px 2%" }}>
                                        <TableView
                                            onClickRow={(flight) => {
                                                this.props.history.push("/remote_mission/flight_details", {
                                                    user,
                                                    flightId: flight.id,
                                                    planName: flight.plan_name,
                                                    projectName: flight.project_name,
                                                    date: flight.end_time,
                                                    startTime: flight.start_time,
                                                    end_time: flight.end_time,
                                                    streamKey: flight.streamkey,
                                                    areaCovered: flight.area_covered,
                                                    areaCoveredMethod: flight.area_calculation_method,
                                                    distanceCovered: flight.distance_covered,
                                                    droneId: flight.drone_id,
                                                    firmwareVersion: flight.firmware_version_number,
                                                    greenBuildNumber: flight.green_build_number,
                                                    droneName: flight.drone_name,
                                                    droneUIN: flight.uin,
                                                    software_edition: flight.software_edition
                                                })
                                            }}
                                            columns={[
                                                { Header: 'Project Name', accessor: 'project_name', width: '15%', },
                                                { Header: 'Drone ID', accessor: 'drone_id', width: '15%', },
                                                { Header: 'Plan Name', accessor: 'plan_name', width: '15%', },
                                                { Header: 'Start Time', accessor: 'start_time', width: '15%', isDate: true,   },
                                                { Header: 'End Time', accessor: 'end_time', width: '15%', isDate: true,   },
                                                { Header: 'Total Flight Time', accessor: 'total_time', width: '15%', },
                                            ]}
                                            data={this.state.logsDisplay.map(log => {
                                                return {
                                                    ...log,
                                                    total_time: getFormatedDate(log.start_time, log.end_time),
                                                    area_covered: (Number(log.area_covered) / 4046.85642).toFixed(4)
                                                }
                                            })}
                                            user={user}
                                            // showSrNo
                                            count={this.state.totalCount}
                                            // showCount
                                            // headingLeft="Flights"
                                            onStateChange={(changedState) => {

                                            }}
                                        />

                                    </div>
                            }
                        </div>
                    </div>
                </div>
            );
    }
}

export default withRouter(RMPAllFlightsLogs)