import React, { useEffect, useState } from "react"
import { getGCSFlightStats, getGCSFlights, getGCSFlightsCSVData, getGCSFlightsGraphStats, deleteFlightData } from "../../api"
import InteractiveGraph from "../Components/InteractiveGraph"
import FlightsFilter from "../Components/FlightsFilter/FlightsFilter"
import "./Flights.css"
import TableView from "../../ReusableComponents/TableView/TableView"
import { useHistory } from "react-router-dom"
import { BreadCrumbs } from "../../ReusableComponents/BreadCrumbs"
import { Popup, addUserAnalytic, updateOutTime } from "../../ReusableComponents/reusableFunctions"
import Cookies from "universal-cookie"
import LoaderComponent from "../../ReusableComponents/LoaderComponent"
import { useFirstRender } from "../../ReusableComponents/firstRender"
import { GCS_FEATURES, getActiveTeam, getFeaturesPermissions } from "../../Teams"
import { ActivityPopup } from "../../ReusableComponents/ActivityPopup"
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import Button from "../../ReusableComponents/Button/Button";
import flightReplay from "../../replayVideo.svg";

export const getFlightTime = (flightObj) => {
    const flights = Object.entries(flightObj).filter(flight => flight[0] !== "milliseconds");

    // If the first two entries are days and hours, include the third entry
    if (flights.length >= 2 && flights[0][0] === 'days' && flights[1][0] === 'hours') {
        if (flights?.[2])
            flights.push([flights[2][0], flights[2][1]]); // Include the third entry
    }

    // flights.splice(2); // Take only the first two entries

    if (flights.length === 0) return `0`;

    let time1 = Number(flights[0][1]);
    let time2 = Number(flights?.[1]?.[1]);
    let interval1 = flights[0][0];
    let interval2 = flights?.[1]?.[0];

    // Modify interval1 and interval2
    switch (interval1) {
        case 'days':
            time1 *= 24; // Convert days to hours
            if (interval2 === 'hours') {
                time1 += time2; // Add hours
                interval1 = 'h'; // Change interval1 to hours
                time2 = flights?.[2]?.[1] || 0; // Reset time2
                interval2 = flights?.[2]?.[0] || ""; // Reset interval2
            } else {
                interval1 = 'h'; // Change interval1 to hours
            }
            break;
        case 'hours':
            interval1 = 'h';
            break;
        case 'minutes':
            interval1 = 'm';
            break;
        case 'seconds':
            interval1 = 's';
            break;
        case 'milliseconds':
            interval1 = 'ms';
            break;
    }

    // Modify interval2
    if (interval2) {
        switch (interval2) {
            case 'days':
                time2 *= 24; // Convert days to hours
                interval2 = 'h'; // Change interval2 to hours
                break;
            case 'hours':
                interval2 = 'h';
                break;
            case 'minutes':
                interval2 = 'm';
                break;
            case 'seconds':
                interval2 = 's';
                break;
            case 'milliseconds':
                interval2 = 'ms';
                break;
        }
    }

    return `${time1} ${interval1} ${interval2 ? `${time2} ${interval2}` : ""}`;

}

const Flights = (props) => {
    const cookies = new Cookies()
    const { user, type } = props.location.state
    const history = useHistory();
    const [flights, setFlights] = useState([])
    const [flightsStats, setFlightsStats] = useState(null)
    const [flightsGraphStats, setFlightsGraphStats] = useState(null)
    const [tableState, setTableState] = useState({
        pageNumber: 1,
        pageSize: 15,
    })
    const [filterState, setFilterState] = useState(null)
    const [flightsTableLoading, setFlightsTableLoading] = useState(false)
    const [cookieData, setCookieData] = useState()
    const [permissions, setPermissions] = useState()
    const [activityPopup, setActivityPopup] = useState(undefined)
    const [deleteData, setDeleteData] = useState(undefined)
    const [outId, setOutId] = useState("")
    const [activeTeam, setActiveTeam] = useState('')


    const getFlights = async () => {
        try {
            setFlightsTableLoading(true)
            const flights = await getGCSFlights({ ...tableState, ...filterState })
            setFlights(flights)
            setFlightsTableLoading(false)
        }
        catch (e) {
            setFlightsTableLoading(false)
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const getFlightsStats = async () => {
        try {
            setFlightsStats(null)
            const flightsStats = await getGCSFlightStats(filterState)
            setFlightsStats(flightsStats)
        }
        catch (e) {
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const getFlightsGraphStats = async () => {
        try {
            setFlightsGraphStats(null)
            const flightsGraphStats = await getGCSFlightsGraphStats(filterState)

            setFlightsGraphStats(flightsGraphStats)
        }
        catch (e) {
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const firstRender = useFirstRender();

    useEffect(() => {
        if (filterState) {
            const page_data = cookies.get("page_data");
            const newPageData = { ...page_data, flights: { ...page_data?.flights, ...filterState } }
            cookies.set('page_data', JSON.stringify(newPageData), { path: "/" })
            getFlightsGraphStats()
            getFlightsStats()
            getFlights()
        }
    }, [filterState])

    useEffect(() => {
        if (!firstRender) {
            if (tableState) {
                const page_data = cookies.get("page_data");
                const newPageData = { ...page_data, flights: { ...page_data?.flights, pageNumber: tableState.pageNumber } }
                cookies.set('page_data', JSON.stringify(newPageData), { path: "/" })
                getFlights()
            }
        }
    }, [tableState])

    useEffect(() => {
        if (!cookieData) {
            const page_data = cookies.get("page_data");
            const data = page_data?.flights || {}
            if (data?.pageNumber)
                setTableState(prev => ({ ...prev, pageNumber: data.pageNumber }))
            setCookieData(data)
        }
        addUserAnalytic(props.location.pathname).then(id => { setOutId(id) })
        getActiveTeam().then(activeTeam => {
            setActiveTeam(activeTeam)
        }, () => {
            getFeaturesPermissions([GCS_FEATURES.PLANS]).then(permissions => {
                console.log("permission called", permissions);
                setPermissions(permissions)
            })
        })

    }, [])

    useEffect(() => { return () => { updateOutTime(outId) } }, [outId])

    const getCookieData = () => {
        return cookies.get("page_data")
    }

    const closeActivityPopup = () => {
        setActivityPopup(undefined)
    }

    const deleteFlight = async () => {
        try {
            setActivityPopup({
                open: true,
                msg: 'Deleting flight and related data please wait...',
                item: "WAIT",
                icon: "WAIT",
            })
            const data = await deleteFlightData(deleteData.id)
            setActivityPopup({
                open: true,
                msg: data.message,
                item: "COMPLETE",
                icon: "COMPLETE",
            })
            setDeleteData(undefined)
            getFlightsGraphStats()
            getFlightsStats()
            getFlights()
        }
        catch (e) {
            setDeleteData(undefined)
            closeActivityPopup()
            Popup.alert("ERROR", e?.message || e)
        }

    }

    return <div className="wrapper" style={{ fontFamily: 'Lato' }}>
        <div className="right-content-new">
            <div className="top-bar-new">
                <div className="top-bar-text-new">
                    <BreadCrumbs
                        data={props.location.state.project ?
                            [
                                {
                                    displayName: "projects",
                                    pathname: "/projects",
                                    state: {
                                        type,
                                        user,
                                    }
                                },
                                {
                                    displayName: props.location.state.project.project_name,
                                    pathname: "/plans",
                                    state: {
                                        type,
                                        user,
                                        plan: props.location.state.plan,
                                        project: props.location.state.project,
                                    }
                                },
                                {
                                    displayName: props.location.state.plan.plan_name,
                                    pathname: "/remote_mission/assignDroneToPlan",
                                    state: {
                                        type,
                                        user,
                                        plan: props.location.state.plan,
                                        project: props.location.state.project,
                                    }
                                },
                                {
                                    displayName: "Flights"
                                }
                            ]
                            : [
                                {
                                    displayName: "AeroGCS",
                                    pathname: "/",
                                    state: {
                                        user
                                    }
                                },
                                {
                                    displayName: 'Flights',
                                }
                            ]} />
                </div>
            </div>
            <div className="main-content-new flights-main-content" >
                {activeTeam.isOwner ? <>
                    <ActivityPopup
                        item={activityPopup?.item}
                        open={activityPopup?.open}
                        icon={activityPopup?.icon}
                        msg={activityPopup?.msg}
                        close={() => { closeActivityPopup() }}
                        onClickOk={() => { deleteFlight() }}
                    />
                    {cookieData ? <>
                        <FlightsFilter
                            stats={{
                                Acres: `${flightsStats?.total_acres_covered ? `${(Number(flightsStats.total_acres_covered) / 4046.85642).toFixed(4)}` : 0}`,
                                Flights: flightsStats?.total_flights || 0,
                                "Flight Time": flightsStats?.total_flight_time ? getFlightTime(flightsStats.total_flight_time) : 0,
                                "Pilots worked": flightsStats?.total_pilots || 0,
                                "Utilize Drones": flightsStats?.total_drones_utilize || 0,
                            }}
                            selectedTab={props.location.state.interval || cookieData?.selectedTab}
                            selectedDrone={props.location.state.selectedDrone || cookieData?.selectedDrones?.[0]}
                            selectedPilot={cookieData?.selectedPilots?.[0]}
                            defaultFromDateTime={cookieData?.fromDateTime}
                            defaultToDateTime={cookieData?.toDateTime}
                            filterState={{ ...tableState, ...filterState }}
                            onFilterDataChange={(data) => {
                                if (!data?.firstTime) {
                                    setTableState(prev => ({ ...prev, pageNumber: 1 }))
                                }
                                setFilterState({ ...data, projectName: props.location?.state?.project?.project_name, planName: props.location?.state?.plan?.plan_name })
                            }}
                            isPilotEnable={true}
                        />

                        <InteractiveGraph
                            showZoom
                            data={flightsGraphStats?.length > 1 ? flightsGraphStats : []}
                            loading={!flightsGraphStats}
                            lineType={0}
                        />
                        <TableView
                            pageNumber={getCookieData()?.flights?.pageNumber || 1}
                            onStateChange={(state) => { setTableState(state) }}
                            isLoading={flightsTableLoading}
                            onClickRow={(flight) => {
                                history.push('/remote_mission/flight_details', {
                                    ...flight,
                                    user: user,
                                    flightId: flight.id,
                                    planName: flight.plan_name,
                                    projectName: flight.project_name,
                                    date: flight.end_time,
                                    startTime: flight.start_time,
                                    end_time: flight.end_time,
                                    streamKey: flight.streamkey,
                                    areaCovered: flight.area_covered,
                                    areaCoveredMethod: flight.area_calculation_method,
                                    distanceCovered: flight.distance_covered,
                                    droneId: flight.drone_id,
                                    firmwareVersion: flight.firmware_version_number,
                                    greenBuildNumber: flight.green_build_number,
                                    droneName: flight.drone_name,
                                    droneUIN: flight.uin,
                                    software_edition: flight.software_edition,

                                });
                            }}
                            onClickLink={(flight) => { props.history.push("/drone_control_center/fly_view", { flight: flight.id, user: user, replyData: 0 }) }}
                            columns={[
                                { Header: "Project Name", accessor: "project_name", width: '10%', isSortable: true },
                                { Header: "Plan Name", accessor: "plan_name", width: '10%', isSortable: true },
                                { Header: "Drone", accessor: "drone_name", width: '10%', isSortable: true },
                                { Header: "Start Time", accessor: "start_time", width: '15%', isSortable: true, isDate: true },
                                { Header: "End Time", accessor: "end_time", width: '15%', isSortable: true, isDate: true },
                                { Header: "Total Time", accessor: "total_flight_time", width: '10%', isSortable: true },
                                { Header: "Distance (M)", accessor: "distance_covered", width: '5%', isSortable: true },
                                { Header: "Area (Acr)", accessor: "area_covered", width: '10%', isSortable: true },
                                { Header: "Replay", accessor: "link_data", width: '5%', isLink: true, isCenter: true },
                            ]}
                            count={flightsStats?.total_flights}
                            data={flights?.map((flight) => {
                                return ({
                                    ...flight,
                                    total_flight_time: getFlightTime(flight.total_flight_time),
                                    area_covered: (Number(flight.area_covered) / 4046.85642).toFixed(4),
                                    link_data: { img: flightReplay }
                                })
                            }) || []}
                            activities={{
                                disableDelete: !permissions?.[GCS_FEATURES.PLANS]?.DELETE,
                                onClickDelete: (flight) => {
                                    setDeleteData(flight)
                                    setActivityPopup({
                                        open: true,
                                        msg: 'Are you sure you want to delete selected flight and related data. (ie. streaming video, images, flight logs) ?',
                                        item: "WARNING",
                                        icon: "WARNING",
                                    })
                                }
                            }}
                            user={user}
                        />
                    </> : <LoaderComponent message="Loading Flights, please wait..." />}
                </> : <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "75vh" }}>
                    {`You dont have access to flights of ${activeTeam?.teamName} `}
                </div>}
                <div style={{ width: "100%", height: "45px" }}></div>
            </div>
        </div>
    </div>
}
export default Flights