import React, { useEffect, useState } from 'react'
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs';
import { getAllDronesErrorCount, getDronesErrorCount, getGCSDroneFlightErrorLogs, getUserType, updateErrorNotificationLastVisited } from './api';
import LoaderComponent from './ReusableComponents/LoaderComponent';
import { debounnceFn, Popup } from './ReusableComponents/reusableFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { GCS_FEATURES, getFeaturesPermissions } from './Teams';
import TableView from './ReusableComponents/TableView/TableView';
import SearchHeader from './ReusableComponents/SearchHeader/SearchHeader';
import Select from 'react-dropdown-select';
let searchFunction;
const FlightErrorLogs = (props) => {
    const history = useHistory()
    const [user, setUser] = useState(null)
    const [lastVisited, setLastVisited] = useState(null)
    const [errors, setErrors] = useState([])
    const [drones, setDrones] = useState([])
    const [selectedDrone, setSelectedDrone] = useState(props?.location?.state?.drone || { drone_name: 'All', drone_id: 'All' })
    const [errorCount, setErrorCount] = useState(0)
    const [permissions, setPermissions] = useState(null)
    const [filter, setFilter] = useState({ fromDateTime: undefined, toDateTime: undefined, pageSize: 15, pageNumber: 1, orderBy: 'timestamp', orderType: 'DESC' })
    const [tableLoading, setTableLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const initData = async () => {
        try {
            if (!user) {
                const user = props?.location?.state?.user
                const permissions = await getFeaturesPermissions([GCS_FEATURES.PLANS])
                setPermissions(permissions)
                if (user) {
                    setUser(user)
                } else {
                    const [blah, type, user] = await getUserType()
                    setUser(user)
                }
            }
            await getErrors(filter)
            if (drones.length == 0) { //only call this is list is empty,so that it is not called on each list change
                await getAllDronesErrorCountFunc()
            }
            setLoading(false)
            setTableLoading(false)
        } catch (err) {
            setLoading(false)
            setTableLoading(false)
            console.log(err)
            Popup.alert("ERROR", err.message || "Something went wrong while getting flight error logs")
        }
    }
    const getErrors = async (f) => {
        setTableLoading(true)
        return new Promise(async (resolve) => {
            try {
                let data = await getGCSDroneFlightErrorLogs({ ...f, drone_id: selectedDrone.drone_id == 'All' ? "" : selectedDrone.drone_id })
                const { errorLogs, count, old_last_visited, new_last_visited } = data
                if (!lastVisited) { //this will make sure last visited is set only once after coming on page
                    await updateErrorNotificationLastVisited(new_last_visited)
                    setLastVisited(old_last_visited)
                }
                setErrors(errorLogs)
                setErrorCount(count)
                setTableLoading(false)
            } catch (err) {
                setTableLoading(false)
                console.log(err)
                Popup.alert("ERROR", err.message || "Something went wrong while getting flight error logs")
            }
            resolve()
        })
    }
    const getAllDronesErrorCountFunc = async () => {
        setTableLoading(true)
        return new Promise(async (resolve) => {
            try {
                const data = await getAllDronesErrorCount()
                setDrones([{ drone_id: 'All', drone_name: 'All' }, ...data.drones.filter(d => d?.drone_id)])
                setTableLoading(false)
            } catch (err) {
                setTableLoading(false)
                console.log(err)
                Popup.alert("ERROR", err.message || "Something went wrong while getting drones with error count")
            }
            resolve()
        })
    }


    const handleFilterChange = (newF, updatedField) => {
        const { fromDateTime, toDateTime } = newF;
        const isoFromDateTime = fromDateTime === undefined ? undefined : new Date(fromDateTime).toISOString();
        const isoToDateTime = toDateTime === undefined ? undefined : new Date(toDateTime).toISOString();
        const newFilter = {
            ...filter,
            ...newF,
            fromDateTime: fromDateTime === undefined || toDateTime === undefined ? undefined : isoFromDateTime,
            toDateTime: fromDateTime === undefined || toDateTime === undefined ? undefined : isoToDateTime,
        }
        setFilter(newFilter)
        if (updatedField === "searchString") {
            newFilter.pageNumber = 1;
            searchFunction(newFilter)
        } else {
            getErrors(newFilter)
        }
    }
    useEffect(() => {
        document.title = "Drones - Error Logs"
        searchFunction = debounnceFn(getErrors, 700)
        initData()
    }, [selectedDrone])
    useEffect(() => {
        if (props?.location?.state?.drone) {
            setSelectedDrone(props?.location?.state?.drone)
        }
    }, [props?.location?.state?.drone?.drone_id])
    return (
        <div className='wrapper'>

            <div
                className="right-content-new"
            >
                {user && !loading ?
                    <><div className="top-bar-new">
                        <div className="top-bar-text-new">
                            <BreadCrumbs
                                data={[
                                    {
                                        displayName: "AeroGCS",
                                        pathname: "/",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: 'Drones',
                                        pathname: "/drone_management/drones",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: 'Error Logs',
                                    }
                                ]} />
                        </div>
                    </div>
                        <div className="main-content-new">
                            <SearchHeader
                                defaultFromDate={filter.fromDateTime}
                                defaultToDate={filter.toDateTime}
                                isShowDateTimePicker={true}
                                placeholder={"Search errors using Flight Id,Error or UIN.."}
                                onFilterDataChange={handleFilterChange}
                            />


                            <div className="drone-errors-table-height" style={{ position: 'relative', marginTop: '37px' }}>
                                <div style={{ position: 'absolute', right: '0', top: '-26px' }}>
                                    <span className='select_dropdown_label'>Select Drone</span>
                                    <Select
                                        disabled={props?.location?.state?.drone?.drone_id}
                                        style={{ maxWidth: "330px", zIndex: 9 }}
                                        placeholder="Search drones..."
                                        searchable={true}
                                        options={drones?.map(drone => (
                                            {
                                                label: `${drone.drone_name || "-"} ${drone.drone_name == 'All' ? "" : `(${drone.error_count})`}` || '',
                                                value: (drone.drone_id || '-')
                                            }))}
                                        values={[{ label: selectedDrone.drone_name, value: selectedDrone.drone_id }]}
                                        className="custom_dropdown"
                                        onChange={(values) => {
                                            if (values?.[0]) {
                                                setFilter(prev => ({ ...prev, pageNumber: 1 }))
                                                setSelectedDrone({ drone_name: values[0].label, drone_id: values[0].value })
                                               
                                            }
                                        }
                                        }
                                        itemRenderer={({ item, itemIndex, props, state, methods }) => {
                                            const drone_name = item.label.split([' '])[0]
                                            const drone_count = item.label.split([' '])[1]
                                            return <span onClick={() => methods.addItem(item)} role="option" aria-selected="true" aria-label="All " tabindex="-1" className={`react-dropdown-select-item react-dropdown-select-item-selected css-z2hyoe-ItemComponent evc32pp0 select-dropdown-item-hover ${methods.isSelected(item) ? "select-dropdown-item-selected" : ""}`} color="#0074D9">
                                                <span>{drone_name}</span>
                                                {" "}
                                                <span style={{ color: 'red' }}>{drone_count}</span>
                                            </span>
                                        }}
                                    />
                                </div>
                                <TableView
                                    isLoading={tableLoading}
                                    onStateChange={(changedState) => {
                                        const newFilter = {
                                            ...filter,
                                            pageNumber: changedState.pageNumber,
                                            orderBy: changedState.orderBy || 'timestamp',
                                            orderType: changedState.orderType || 'DESC'
                                        }
                                        setFilter(newFilter)
                                        getErrors(newFilter)
                                    }}
                                    headingLeft={`Error Logs`}
                                    onClickRow={(error) => {
                                        history.push('/remote_mission/flight_details',
                                            {
                                                user,
                                                flightId: error.flight.id,
                                                planName: error.flight.plan_name,
                                                projectName: error.flight.project_name,
                                                date: error.flight.end_time,
                                                startTime: error.flight.start_time,
                                                end_time: error.flight.end_time,
                                                streamKey: error.flight.streamkey,
                                                areaCovered: (Number(error.flight.area_covered) / 4046.85642).toFixed(4),
                                                areaCoveredMethod: error.flight.area_calculation_method,
                                                distanceCovered: error.flight.distance_covered,
                                                droneId: error.flight.drone_id,
                                                firmwareVersion: error.flight.firmware_version_number,
                                                greenBuildNumber: error.flight.green_build_number,
                                                droneName: error.drone_name,
                                                droneUIN: error.uin,
                                                software_edition: error.flight.software_edition
                                            })
                                    }}
                                    columns={[
                                        { Header: "Error", accessor: "error_message", width: '11%' },
                                        { Header: "Date & time", accessor: "timestamp", width: '11%', isDate: true,   isSortable: true },
                                        { Header: "Flight ID", accessor: "flight_id", width: '11%', },
                                        { Header: "UIN", accessor: "uin", width: '11%', },
                                        { Header: "Type", accessor: "error_type", width: '11%', },
                                    ]}
                                    count={errorCount || 0}
                                    data={errors.map(err => {
                                        if (new Date(err.timestamp) >= new Date(lastVisited)) {
                                            return {
                                                ...err, error_message: <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <div style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        flex: 1
                                                    }} >{err.error_message}</div>
                                                    <div style={{ color: 'red', fontWeight: 600, marginLeft: '5px', width: '20px', flexShrink: 0, fontSize: '20px' }}>*</div>
                                                </div>
                                            };
                                        }
                                        else return err
                                    }) || []}
                                    activities={null}
                                />
                            </div>
                        </div>
                    </> : <LoaderComponent
                        height='100%'
                        width='100%'

                    />
                }

            </div>
        </div>
    )
}

export default FlightErrorLogs