import React, { useEffect, useState } from 'react';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal';
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon';
import { isTeamOwner } from '../../Teams';
import moment from 'moment'

const SubscriptionExpireModal = ({ data, updateProfile }) => {

  const [isOwner, setIsOwner] = useState(false);
  const [showPopup, setShowPopup] = useState(true)
  const [hideClose, setHideClose] = useState(true)
  const checkIsOwner = async () => {
    setIsOwner(await isTeamOwner())
  }

  useEffect(() => {
    let gracePeriodEndDate = new Date(data.expiry_date)
    gracePeriodEndDate.setDate(gracePeriodEndDate.getDate() + 15)
    let currentDate = new Date()
    console.log('data.expiry_date', data.expiry_date, gracePeriodEndDate);
    if (currentDate > gracePeriodEndDate) {
      setHideClose(false)
    }
    checkIsOwner()
  }, []);

  return (showPopup && <AnimatedModal
    isOpen={true} // (data.error || data.storageExceed || data.isExpired || !data.isValidStoragePlan)
    height="200px"
    width="460px"
  >
    <div style={{ height: "100%", width: "100%", padding: '0 5px' }}>
      <div style={{ padding: '0px 35px', fontSize: "15px", color: "#3c3c3c", width: '100%', height: '130px', display: 'flex', alignItems: 'center' }}>
        {data.error ? `Something went wrong while getting your service plan, please contact us.`
          : !data.isValidStoragePlan ?
            `${isOwner ? `${data.errorMessage}`
              : `It appears that your ${data.service_name} subscription expired of the selected team. Please contact the team owner for assistance.`}`
            : data.isExpired ?
              !isOwner ? `Your ${data.plan_name} subscription has expired for selected team, please renew`
                : `Your ${data.plan_name} subscription has expired, please renew it`
              : data.storageExceed &&
                !isOwner ? `It appears that your ${data.plan_name} plan exceeds the size of the selected team. Please contact the team owner for assistance.`
                : `You have exceeded the storage limit of your ${data.plan_name} account. The storage usage is ${(data.storageUsed / 1024).toFixed(4)} / ${(data.storageLimit / 1024).toFixed(4)} GB.  Please upgrade the plan `
        }
      </div>
      <hr style={{ margin: '0px 0px 5px 0px', padding: '0' }} />
      <div style={{ padding: '0px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '50px' }} >
        <ButtonWithIcon
          isBtnActive
          text={"Close"}
          linkData={data.error || !isOwner || hideClose ? undefined : {
            pathname: "/profile-settings",
            state: {
              user: data?.user
            }
          }}
          onClick={() => {
            if (!hideClose) {
              setShowPopup(false)
            }
          }}
        />
        <ButtonWithIcon
          isBtnActive
          text={data.error || !isOwner ? "Ok" : !data.isValidStoragePlan ? "Buy" : "Renew"}
          onClick={data.error || !isOwner ? () => {
            if (data.isValidStoragePlan && data.isExpired && !isOwner) {
              updateProfile()
            }
            else window.history.back();
          } : undefined}
          linkData={data.error || !isOwner ? undefined : {
            pathname: "/aeromegh_plans",
            state: {
              serviceName: data.service_name,
              user: data?.user
            }
          }}
        />
      </div>
    </div>
  </AnimatedModal>)
}

export default SubscriptionExpireModal;