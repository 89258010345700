import React, { useEffect, useRef, useState } from 'react'
import { getAllDroneLocations, getUserType } from './api.js';
import { Popup } from './ReusableComponents/reusableFunctions.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import TableView from './ReusableComponents/TableView/TableView.js';
import Map from "./MapComponent.js";
import Search from './ReusableComponents/SearchHeader/Search.js';
import _debounce from 'lodash/debounce';

const AdminAllDronesLocations = (props) => {
    const isFirstRender = useRef(true)
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true)
    const [dataLoading, setDataLoading] = useState(true)
    const [drones, setDrones] = useState([])
    const [droneCount, setDroneCount] = useState(undefined)
    const [isMapView, setIsMapView] = useState(false)

    const [filter, setFilter] = useState({
        searchString: '',
        fromDateTime: undefined,
        toDateTime: undefined,
        orderBy: 'last_keep_alive',
        orderType: 'DESC',
        pageSize: 15,
        pageNumber: 1,
    })

    const isAdmin = true

    const getDroneLocationsForTable = async () => {
        try {
            const string = filter.searchString ? filter.searchString : ''
            getAllDroneLocations(string, isAdmin, filter).then(droneLocations => {
                setDrones(droneLocations.drones || [])
                setDroneCount(droneLocations.droneCount)
                setLoading(false)
                setDataLoading(false)
            })
        }
        catch (e) {
            setLoading(false)
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const getDroneLocationsForMap = async () => {
        try {
            const string = filter.searchString ? filter.searchString : ''
            getAllDroneLocations(string, isAdmin, {}).then(droneLocations => {
                setDrones(droneLocations.drones || [])
                setDroneCount(droneLocations.droneCount)
                setLoading(false)
                setDataLoading(false)
            })
        } catch (e) {
            setLoading(false)
            setDataLoading(false)
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const getUser = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const [blah, type, user] = await getUserType();
                setUser(user);
                resolve();
            } catch (err) {
                console.log("err : ", err);
                reject(err)
            }
        })
    }

    const initData = async () => {
        try {
            document.title = "All Drones Location - Admin";
            setDataLoading(true)
            await getUser()
            await getDroneLocationsForTable()
        }
        catch (e) {
            console.log(e);
            setDataLoading(false)
            Popup.alert("ERROR", e.message)
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setDataLoading(true)
        if (isMapView) {
            getDroneLocationsForMap()
        } else {
            getDroneLocationsForTable()
        }
    }, [isMapView]);

    useEffect(() => {
        if (isFirstRender.current) {
            initData()
            isFirstRender.current = false;
            return;
        }

        const debouncedGetAllDSPs = _debounce(() => {
            setDataLoading(true)
            if (isMapView) {
                getDroneLocationsForMap()
            } else {
                getDroneLocationsForTable()
            }
        }, 1000);

        debouncedGetAllDSPs();
        return () => {
            setDataLoading(false)
            debouncedGetAllDSPs.cancel();
        };
    }, [filter])

    return <div className="wrapper">
        <div className="right-content-new" style={{ zIndex: 0 }}>
            <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">All Drones Location</div></div>
            {loading ? <LoaderComponent />
                : <>
                    <div className="mapHeaderData">
                        <div style={{ height: '60px', }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div className={'reusable-table-title-container'} style={{ width: '40%' }}>
                                    <div className='reusable-table-title'>Total Drones ({droneCount?.total_drones || 0})</div>
                                    <div className='reusable-table-title'> Active ({droneCount?.active_drones || 0})</div>
                                    <div className='reusable-table-title'> In-Active ({droneCount?.inactive_drones || 0})</div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: '60%', justifyContent: 'flex-end' }}>
                                    < Search placeholder={"Search by UIN, Drone Id, Location and Manufacturer"}
                                        isDisabled={dataLoading}
                                        globalState={{ searchString: filter.searchString }}
                                        handleStateChange={(key, value) => { setFilter({ ...filter, searchString: value, pageNumber: 1 }) }}
                                        style={{ width: "50%" }}
                                    />
                                    <div className="add-user-form-text-wrapper" style={{ marginLeft: '20px', display: "flex", justifyContent: "center", alignItems: "center", }} >
                                        <div className="imageViewButton" style={{ display: 'flex', height: '24px' }}  >
                                            <div
                                                className={!isMapView ? 'imageViewButtonListActive' : 'imageViewButtonList'}
                                                style={{ opacity: dataLoading ? '0.5' : '1', cursor: dataLoading ? 'not-allowed' : 'pointer', width: "66px", borderRadius: '2px 0px 0px 2px', borderRight: 'solid 1px #2989cf' }}
                                                onClick={() => {
                                                    if (!dataLoading) setIsMapView(false)
                                                }} >
                                                List
                                            </div>
                                            <div
                                                className={isMapView ? 'imageViewButtonListActive' : 'imageViewButtonList'}
                                                style={{ opacity: dataLoading ? '0.5' : '1', cursor: dataLoading ? 'not-allowed' : 'pointer', width: "66px", borderRight: 'solid 1px #2989cf', borderLeft: 'solid 1px #2989cf' }}
                                                onClick={() => {
                                                    if (!dataLoading) setIsMapView(true)
                                                }} >
                                                Map
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isMapView ? <div className="main-content main-content-dcc" style={{ height: "calc(100% - 130px)", paddingTop: "1px", paddingLeft: "0px", paddingRight: "0px", display: "flex", }}>
                        {dataLoading ? <LoaderComponent />
                            : <Map
                                className="dcc-all-drones-map"
                                recenterMap={true}
                                locationsData={drones}
                                showTooltip={true}
                                markerClickCallback={(drone) => {
                                    props.history.push('/admin/drone_flight_locations', {
                                        user: user,
                                        drone: drone
                                    });
                                }}
                                showIcons={true}
                                includePortsInRecenter={true}
                            />}
                    </div>
                        : <div className='main-content-new' style={{ height: 'calc(100vh - 135px)', minHeight: 'calc(100vh - 135px)' }}>
                            <div style={{ height: 'calc(100% - 20px)' }}>
                                <TableView
                                    isLoading={dataLoading}
                                    pageNumber={filter.pageNumber}
                                    columns={[
                                        { Header: "Drone Id", accessor: "drone_id", isSortable: true, width: '16%' },
                                        { Header: "UIN", accessor: "uin", isSortable: true, width: '16%' },
                                        { Header: "Manufacturer", accessor: "username", isSortable: true, width: '16%' },
                                        { Header: "Last Cords", accessor: "latLng", width: '16%' },
                                        { Header: "Location", accessor: "location", width: '16%' },
                                        { Header: "Last Flight Date", accessor: "last_keep_alive", width: '16%', isSortable: true, isDate: true, },
                                    ]}
                                    count={droneCount.total_drones}
                                    data={drones || []}
                                    user={user}
                                    onStateChange={(state) => {
                                        setFilter({
                                            ...filter,
                                            ...state
                                        })
                                    }}
                                    onClickRow={(drone) => {
                                        props.history.push('/admin/drone_flight_locations', {
                                            user: user,
                                            drone: drone
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    </div >
};
export default AdminAllDronesLocations