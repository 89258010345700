import React, { useEffect, useState } from "react"
import { getGCSFlightStats, getGCSFlights, getGCSFlightsAcresGraphStats, getGCSFlightsGraphStats, deleteFlightData } from "../../api"
import InteractiveGraph from "../Components/InteractiveGraph"
import FlightsFilter from "../Components/FlightsFilter/FlightsFilter"
import "../Flights/Flights.css"
import TableView from "../../ReusableComponents/TableView/TableView"
import { useHistory } from "react-router-dom"
import { getFlightTime } from "../Flights/Flights"
import { BreadCrumbs } from "../../ReusableComponents/BreadCrumbs"
import { Popup, addUserAnalytic, updateOutTime } from "../../ReusableComponents/reusableFunctions"
import Cookies from "universal-cookie"
import LoaderComponent from "../../ReusableComponents/LoaderComponent"
import { useFirstRender } from "../../ReusableComponents/firstRender"
import { ActivityPopup } from "../../ReusableComponents/ActivityPopup"
import { GCS_FEATURES, getFeaturesPermissions } from "../../Teams"
import flightReplay from "../../replayVideo.svg";

const Acres = (props) => {
    const cookies = new Cookies()
    const history = useHistory();
    const { user } = props.location.state
    const [flights, setFlights] = useState(null)
    const [flightAcresStats, setFlightAcresStats] = useState(null)
    const [flightsAcresGraphStats, setFlightsAcresGraphStats] = useState(null)
    const [tableState, setTableState] = useState({
        pageNumber: 1,
        pageSize: 15,
    })
    const [filterState, setFilterState] = useState(null)
    const [flightsTableLoading, setFlightsTableLoading] = useState(false)
    const [cookieData, setCookieData] = useState()
    const [permissions, setPermissions] = useState()
    const [activityPopup, setActivityPopup] = useState(undefined)
    const [deleteData, setDeleteData] = useState(undefined)
    const [outId, setOutId] = useState("")


    const getFlights = async () => {
        try {
            setFlightsTableLoading(true)
            const flights = await getGCSFlights({ ...tableState, ...filterState })
            setFlights(flights)
            setFlightsTableLoading(false)
        }
        catch (e) {
            setFlightsTableLoading(false)
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const firstRender = useFirstRender()
    useEffect(() => {
        if (!firstRender) {
            if (tableState) {
                const page_data = cookies.get("page_data");
                const newPageData = {
                    ...page_data,
                    acres: {
                        ...page_data?.acres,
                        pageNumber: tableState.pageNumber
                    }
                }
                cookies.set('page_data', JSON.stringify(newPageData), { path: "/" })
                getFlights()
            }
        }
    }, [tableState])

    useEffect(() => { return () => { updateOutTime(outId) } }, [outId])

    useEffect(() => {
        if (!cookieData) {
            const page_data = cookies.get("page_data");
            const data = page_data?.acres || {}
            if (data?.pageNumber)
                setTableState(prev => ({ ...prev, pageNumber: data.pageNumber }))
            setCookieData(data)
        }
        addUserAnalytic(props.location.pathname).then(id => { setOutId(id) })
        getFeaturesPermissions([GCS_FEATURES.PLANS]).then(permissions => { setPermissions(permissions) })
    }, [])

    const getAcresStats = async () => {
        try {
            setFlightAcresStats(null)
            const flightAcresStats = await getGCSFlightStats(filterState)
            setFlightAcresStats(flightAcresStats)
        }
        catch (e) {
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const getFlightsAcresGraphStats = async () => {
        try {
            setFlightsAcresGraphStats(null)
            const flightsAcresGraphStats = await getGCSFlightsAcresGraphStats(filterState)
            setFlightsAcresGraphStats(flightsAcresGraphStats)
        }
        catch (e) {
            Popup.alert("ERROR", e?.message || e)
        }
    }

    useEffect(() => {
        if (filterState) {
            const page_data = cookies.get("page_data");
            const newPageData = { ...page_data, acres: { ...page_data?.acres, ...filterState } }
            cookies.set('page_data', JSON.stringify(newPageData), { path: "/" })
            getFlightsAcresGraphStats()
            getAcresStats()
            getFlights()
        }
    }, [filterState])

    const getCookieData = () => {
        return cookies.get("page_data")
    }

    const closeActivityPopup = () => {
        setActivityPopup(undefined)
    }

    const deleteFlight = async () => {
        try {
            setActivityPopup({
                open: true,
                msg: 'Deleting flight and related data please wait...',
                item: "WAIT",
                icon: "WAIT",
            })
            const data = await deleteFlightData(deleteData.id)
            setActivityPopup({
                open: true,
                msg: data.message,
                item: "COMPLETE",
                icon: "COMPLETE",
            })
            setDeleteData(undefined)
            getFlightsAcresGraphStats()
            getAcresStats()
            getFlights()
        }
        catch (e) {
            setDeleteData(undefined)
            closeActivityPopup()
            Popup.alert("ERROR", e?.message || e)
        }

    }

    return <div className="wrapper" style={{ fontFamily: 'Lato' }}>
        <div className="right-content-new">
            <div className="top-bar-new">
                <div className="top-bar-text-new">
                    <BreadCrumbs
                        data={[
                            {
                                displayName: "AeroGCS",
                                pathname: "/",
                                state: {
                                    user
                                }
                            },
                            {
                                displayName: 'Acres',
                            }
                        ]} />
                </div>
            </div>
            <div className="main-content-new flights-main-content" >
                <ActivityPopup
                    item={activityPopup?.item}
                    open={activityPopup?.open}
                    icon={activityPopup?.icon}
                    msg={activityPopup?.msg}
                    close={() => { closeActivityPopup() }}
                    onClickOk={() => { deleteFlight() }}
                />
                {cookieData ?
                    <>
                        <FlightsFilter
                            stats={{
                                Acres: `${flightAcresStats?.total_acres_covered ? `${(Number(flightAcresStats.total_acres_covered) / 4046.85642).toFixed(4)}` : 0}`,
                                Flights: flightAcresStats?.total_flights || 0,
                                "Flight Time": flightAcresStats?.total_flight_time ? getFlightTime(flightAcresStats.total_flight_time) : 0,
                                "Pilots worked": flightAcresStats?.total_pilots || 0,
                                "Utilize Drones": flightAcresStats?.total_drones_utilize || 0,
                            }}
                            isPilotEnable
                            selectedTab={props.location.state.interval || cookieData?.selectedTab}
                            selectedDrone={props.location.state.selectedDrone || cookieData?.selectedDrones?.[0]}
                            selectedPilot={cookieData?.selectedPilots?.[0]}
                            defaultFromDateTime={cookieData?.fromDateTime}
                            defaultToDateTime={cookieData?.toDateTime}
                            filterState={{ ...tableState, ...filterState }}
                            onFilterDataChange={(data) => {
                                if (!data?.firstTime) {
                                    setTableState(prev => ({ ...prev, pageNumber: 1 }))
                                }
                                setFilterState(data)
                            }} />
                        <InteractiveGraph
                            showZoom
                            data={flightsAcresGraphStats?.length > 1 ? flightsAcresGraphStats?.map((stat) => {
                                return {
                                    ...stat,
                                    value: Number(stat.value) > 0 ? (Number(stat.value) / 4046.85642).toFixed(4) : Number(stat.value)
                                }
                            }) : []}
                            lineType={0}
                            loading={!flightsAcresGraphStats}
                            showDecimalValues={true}
                        />
                        <TableView
                            pageNumber={getCookieData()?.acres?.pageNumber || 1}
                            onStateChange={(state) => { setTableState(state) }}
                            headingLeft={"Flights"}
                            isLoading={flightsTableLoading}
                            onClickRow={(flight) => {
                                history.push('/remote_mission/flight_details', {
                                    user: user,
                                    flightId: flight.id,
                                    planName: flight.plan_name,
                                    projectName: flight.project_name,
                                    date: flight.end_time,
                                    startTime: flight.start_time,
                                    end_time: flight.end_time,
                                    streamKey: flight.streamkey,
                                    areaCovered: flight.area_covered,
                                    areaCoveredMethod: flight.area_calculation_method,
                                    distanceCovered: flight.distance_covered,
                                    droneId: flight.drone_id,
                                    firmwareVersion: flight.firmware_version_number,
                                    greenBuildNumber: flight.green_build_number,
                                    droneName: flight.drone_name,
                                    droneUIN: flight.uin,
                                    software_edition: flight.software_edition
                                });
                            }}
                            onClickLink={(flight) => { props.history.push("/drone_control_center/fly_view", { flight: flight.id, user: user, replyData: 0 }) }}
                            columns={[
                                { Header: "Project Name", accessor: "project_name", width: '10%', isSortable: true },
                                { Header: "Plan Name", accessor: "plan_name", width: '10%', isSortable: true },
                                { Header: "Drone", accessor: "drone_name", width: '10%', isSortable: true },
                                { Header: "Start Time", accessor: "start_time", width: '10%', isSortable: true, isDate: true,   },
                                { Header: "End Time", accessor: "end_time", width: '10%', isSortable: true, isDate: true,   },
                                { Header: "Total time", accessor: "total_flight_time", width: '10%', isSortable: true },
                                { Header: "Distance (M)", accessor: "distance_covered", width: '10%', isSortable: true },
                                { Header: "Area (Acr)", accessor: "area_covered", width: '10%', isSortable: true },
                                { Header: "Replay", accessor: "link_data", width: '10%', isLink: true, isCenter: true },
                            ]}
                            count={flightAcresStats?.total_flights}
                            data={flights?.map((flight) => {
                                return ({
                                    ...flight,
                                    total_flight_time: getFlightTime(flight.total_flight_time),
                                    area_covered: (Number(flight.area_covered) / 4046.85642).toFixed(4),
                                    link_data: { img: flightReplay }
                                })
                            }) || []}
                            activities={{
                                disableDelete: !permissions?.[GCS_FEATURES.PLANS]?.DELETE,
                                onClickDelete: (flight) => {
                                    setDeleteData(flight)
                                    setActivityPopup({
                                        open: true,
                                        msg: 'Are you sure you want to delete selected flight and related data. (ie. streaming video, images, flight logs) ?',
                                        item: "WARNING",
                                        icon: "WARNING",
                                    })
                                }
                            }}
                            user={user}
                        />
                        <div style={{ width: "100%", height: flightAcresStats?.total_flights >= 15 ? "0" : "25px" }}></div>
                    </> :
                    <LoaderComponent />
                }
            </div>
        </div>
    </div>
}
export default Acres